import { PureComponent } from 'react';

export default class Layout extends PureComponent {
  componentDidMount() {
    window.location = 'https://www.campaign-x.com/';
  }

  render() {
    return null;
  }
}
